const config = {
	/* Initialize Firebase
	 * PASTE YOUR FIREBASE API KEY HERE
	*/
	apiKey: 'AIzaSyD8nW_3O72LZbWUqjPC8SNyIY8wVdAraDU',
	authDomain: 'gamesdb-a5662.firebaseapp.com',
	databaseURL: 'https://gamesdb-a5662.firebaseio.com',
	projectId: 'gamesdb-a5662',
	storageBucket: 'gamesdb-a5662.appspot.com',
	messagingSenderId: '1014170170608',
};

export default config;
